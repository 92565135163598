import { urlApi } from '@app/config/constants';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';

import { User, UserResponse } from '@shared/models/user.interface';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);
  private roleUser = new BehaviorSubject<string>(null);
  private userToken = new BehaviorSubject<string>(null);
  private userId = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.checkToken();
    this.decodeToken();
  }

  get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  get getRoleUser(): Observable<string>{
    return this.roleUser.asObservable();
  }

  get getIdUser(): Observable<number>{
    return this.userId.asObservable();
  }

  get userTokenValue(): string {
    return this.userToken.getValue();
  }

  login(authData: User): Observable<UserResponse | void> {
    return this.http.post<UserResponse>(`${urlApi}/auth/login`, authData)
      .pipe(
        map((user: UserResponse) => {
          this.saveRoken(user.token);
          this.loggedIn.next(true);
          this.roleUser.next(this.getRole());
          this.userId.next(this.getId());
          this.userToken.next(user.token);
          return user;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  logout(): void {
    localStorage.removeItem('token');
    this.loggedIn.next(false);
    this.userToken.next(null);
    this.roleUser.next(null);
    this.userId.next(null);
    this.router.navigate(['']);
  }

  getRole(): string {
    const data = this.decodeToken();
    const role = data.role;
    return role;
  }
  getId(): number {
    const data = this.decodeToken();
    const id = data.sub;
    return id;
  }

  getData(): any {
    return this.decodeToken();
  }

  private checkToken(): void {
    const userToken = localStorage.getItem('token');
    const isExpired = helper.isTokenExpired(userToken);
    if (isExpired) {
      this.logout();
    } else {
      this.loggedIn.next(true);
      this.userToken.next(userToken);
    }
  }
  private decodeToken(): any {
    const userToken = localStorage.getItem('token');
    const data = helper.decodeToken(userToken);
    return data;
  }
  private saveRoken(token: string): void {
    localStorage.setItem('token', token);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    const serverErrorMessage = err.error;

    if (serverErrorMessage) {
      Swal.fire({
        icon: 'error',
        confirmButtonColor: '#2f323a',
        title: 'Acceso denegado',
        html: '<h4>Datos de acceso invalidos, el usuario de inicio de sesión o la contraseña no coinciden.</h4>',
        width: 500,
        padding: '2em',
        showConfirmButton: false,
        timer: 3000
      });
    }
    return throwError(serverErrorMessage);
  }
}
