import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class FormsGroupService {

  validators = [Validators.required];

  constructor() { }


  getAllFormV(): {} {
    return {
      ['1']: ['', this.validators],
      ['2']: ['', this.validators],
      ['3']: ['', this.validators],
      ['4']: ['', this.validators],
      ['5']: ['', this.validators],
      ['6']: ['', this.validators],
      ['7']: ['', this.validators],
      ['8']: ['', this.validators],
      ['9']: ['', this.validators],
      ['10']: ['', this.validators],
      ['11']: ['', this.validators],
      ['12']: ['', this.validators],
      ['13']: ['', this.validators],
      ['14']: ['', this.validators],
      ['15']: ['', this.validators],
      ['16']: ['', this.validators],
      ['17']: ['', this.validators],
      ['18']: ['', this.validators],
      ['19']: ['', this.validators],
      ['20']: ['', this.validators],
      ['21']: ['', this.validators],
      ['22']: ['', this.validators],
      ['23']: ['', this.validators],
      ['24']: ['', this.validators],
      ['25']: ['', this.validators],
      ['26']: ['', this.validators],
      ['27']: ['', this.validators],
      ['28']: ['', this.validators],
      ['29']: ['', this.validators],
      ['30']: ['', this.validators],
      ['31']: ['', this.validators],
      ['32']: ['', this.validators],
      ['33']: ['', this.validators],
      ['34']: ['', this.validators],
      ['35']: ['', this.validators],
      ['36']: ['', this.validators],
      ['37']: ['', this.validators],
      ['38']: ['', this.validators],
      ['39']: ['', this.validators],
      ['40']: ['', this.validators],
      ['41']: ['', this.validators],
      ['42']: ['', this.validators],
      ['43']: ['', this.validators],
      ['44']: ['', this.validators],
      ['45']: ['', this.validators],
      ['46']: ['', this.validators],
      ['47']: ['', this.validators],
      ['48']: ['', this.validators],
      ['49']: ['', this.validators],
      ['50']: ['', this.validators],
      ['51']: ['', this.validators],
      ['52']: ['', this.validators],
      ['53']: ['', this.validators],
      ['54']: ['', this.validators],
      ['55']: ['', this.validators],
      ['56']: ['', this.validators],
      ['57']: ['', this.validators],
      ['58']: ['', this.validators],
      ['59']: ['', this.validators],
      ['60']: ['', this.validators],
      ['61']: ['', this.validators],
      ['62']: ['', this.validators],
      ['63']: ['', this.validators],
      ['64']: ['', this.validators],
      ['65']: ['', this.validators],
      ['66']: ['', this.validators],
      ['67']: ['', this.validators],
      ['68']: ['', this.validators],
      ['69']: ['', this.validators],
      ['70']: ['', this.validators],
      ['71']: ['', this.validators],
      ['72']: ['', this.validators],
      ['73']: ['', this.validators],
      ['74']: ['', this.validators],
      ['75']: ['', this.validators],
      ['76']: ['', this.validators],
      ['77']: ['', this.validators],
      ['78']: ['', this.validators],
      ['79']: ['', this.validators],
      ['80']: ['', this.validators],
      ['81']: ['', this.validators],
      ['82']: ['', this.validators],
      ['83']: ['', this.validators],
      ['84']: ['', this.validators],
      ['85']: ['', this.validators],
      ['86']: ['', this.validators],
      ['87']: ['', this.validators],
      ['88']: ['', this.validators],
      ['89']: ['', this.validators],
      ['90']: ['', this.validators],
      ['91']: ['', this.validators],
      ['92']: ['', this.validators],
      ['93']: ['', this.validators],
      ['94']: ['', this.validators],
      ['95']: ['', this.validators],
      ['96']: ['', this.validators],
      ['97']: ['', this.validators],
      ['98']: ['', this.validators],
      ['99']: ['', this.validators],
      ['100']: ['', this.validators],
      ['101']: ['', this.validators],
      ['102']: ['', this.validators],
      ['103']: ['', this.validators],
      ['104']: ['', this.validators],
      ['105']: ['', this.validators],
      ['106']: ['', this.validators],
      ['107']: ['', this.validators],
      ['108']: ['', this.validators],
      ['109']: ['', this.validators],
      ['110']: ['', this.validators],
      ['111']: ['', this.validators],
      ['112']: ['', this.validators],
      ['113']: ['', this.validators],
      ['114']: ['', this.validators],
      ['115']: ['', this.validators],
      ['116']: ['', this.validators],
      ['117']: ['', this.validators],
      ['118']: ['', this.validators],
      ['119']: ['', this.validators],
      ['120']: ['', this.validators],
      ['121']: ['', this.validators],
      ['122']: ['', this.validators],
      ['123']: ['', this.validators],
      ['124']: ['', this.validators],
      ['125']: ['', this.validators],
      ['126']: ['', this.validators],
      ['127']: ['', this.validators],
      ['128']: ['', this.validators],
      ['129']: ['', this.validators],
      ['130']: ['', this.validators],
      ['131']: ['', this.validators],
      ['132']: ['', this.validators],
      ['133']: ['', this.validators],
      ['134']: ['', this.validators],
      ['135']: ['', this.validators],
      ['136']: ['', this.validators],
      ['137']: ['', this.validators],
      ['138']: ['', this.validators],
      ['139']: ['', this.validators],
      ['140']: ['', this.validators],
      ['141']: ['', this.validators],
      ['142']: ['', this.validators],
      ['143']: ['', this.validators],
      ['144']: ['', this.validators],
      ['145']: ['', this.validators],
      ['146']: ['', this.validators],
      ['147']: ['', this.validators],
      ['148']: ['', this.validators],
      ['149']: ['', this.validators],
      ['150']: ['', this.validators],
      ['151']: ['', this.validators],
      ['152']: ['', this.validators],
      ['153']: ['', this.validators],
      ['154']: ['', this.validators],
      ['155']: ['', this.validators],
      ['156']: ['', this.validators],
      ['157']: ['', this.validators],
      ['158']: ['', this.validators],
      ['159']: ['', this.validators],
      ['160']: ['', this.validators],
      ['161']: ['', this.validators],
      ['162']: ['', this.validators],
      ['163']: ['', this.validators],
      ['164']: ['', this.validators],
      ['165']: ['', this.validators],
      ['166']: ['', this.validators],
      ['167']: ['', this.validators],
      ['168']: ['', this.validators],
      ['169']: ['', this.validators],
      ['170']: ['', this.validators],
      ['171']: ['', this.validators],
      ['172']: ['', this.validators],
      ['173']: ['', this.validators],
      ['174']: ['', this.validators],
      ['175']: ['', this.validators],
      ['176']: ['', this.validators],
      ['177']: ['', this.validators],
      ['178']: ['', this.validators],
      ['179']: ['', this.validators],
      ['180']: ['', this.validators],
      ['181']: ['', this.validators],
      ['182']: ['', this.validators],
      ['183']: ['', this.validators],
      ['184']: ['', this.validators],
      ['185']: ['', this.validators],
      ['186']: ['', this.validators],
      ['187']: ['', this.validators],
      ['188']: ['', this.validators],
      ['189']: ['', this.validators],
      ['190']: ['', this.validators],
      ['191']: ['', this.validators],
      ['192']: ['', this.validators],
      ['193']: ['', this.validators],
      ['194']: ['', this.validators],
      ['195']: ['', this.validators],
      ['196']: ['', this.validators],
      ['197']: ['', this.validators],
      ['198']: ['', this.validators],
      ['199']: ['', this.validators],
      ['200']: ['', this.validators],
      ['201']: ['', this.validators],
      ['202']: ['', this.validators],
































    };
  }
}
