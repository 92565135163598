import { AuthService } from '@pages/auth/auth.service';
import { Observable } from 'rxjs';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if (!req.url.includes('auth/login') || !req.url.includes('uploads')) {
      const authToken = this.authService.userTokenValue;
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'Access-Control-Allow-Origin': '*',
        }
      });
      return next.handle(authReq);

    }
    return next.handle(req);
  }
}
