import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { CheckLoginGuard } from '@shared/guards/check-login.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [CheckLoginGuard],
    loadChildren: () =>
      import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'notFound',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
